import axios from "axios";

export function useConfigService() {
    const config = useRuntimeConfig();
    const api = axios.create({
        baseURL: `${config.public.baseUrl}/api/config`,
    });

    async function getFeatureFlags(
        keys: string[],
    ): Promise<{ [key: string]: boolean }> {
        const { data } = await api.get("/features", {
            params: {
                keys: keys.join(","),
            },
        });
        return data;
    }

    async function getConfiguration(
        keys: string[],
    ): Promise<{ [key: string]: string }> {
        const { data } = await api.get("/config", {
            params: {
                keys: keys.join(","),
            },
        });
        return data;
    }
    return { getFeatureFlags, getConfiguration };
}
