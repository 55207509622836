<template>
    <UModal :ui="{ wrapper: 'z-[9999]' }" v-model="dialogs.upgrade">
        <UCard>
            <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button type="button"
                    class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    @click="closeDialog">
                    <span class="sr-only">Close</span>
                    <Icon name="heroicons:x-mark-20-solid" class="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <div class="flex items-center">
                <div
                    class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-wobby-purple-100 sm:mx-0 sm:h-10 sm:w-10">
                    <Icon name="heroicons:rocket-launch" class="h-6 w-6 text-wobby-purple-600" aria-hidden="true" />
                </div>
                <div class="text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <p class="text-2xl text-gray-800 font-fakt-medium">Usage Limits Exceeded</p>
                </div>

            </div>
            <div class="mt-5">
                <p v-if="appstore.dialogExtraMessage" class="text-base text-gray-600">{{ appstore.dialogExtraMessage }}
                </p>

                <p v-else class="text-base text-gray-600">Looks like the usage for your organization has reached its
                    limits, please contact your organization administrator</p>

            </div>
            <div class="mt-2">
                <NuxtLink to="/account/billing" @click="dialogs.upgrade = false" class="text-base text-wobby-purple-600 hover:text-wobby-purple-700">
                    <UButton block color="primary">Upgrade
                        your plan</UButton>
                </NuxtLink>
            </div>
        </UCard>
    </UModal>
</template>

<script setup lang="ts">
const appstore = useAppStore();
const { dialogs } = storeToRefs(appstore)
const { user } = storeToRefs(useIdentityStore());

const closeDialog = () => {
    appstore.dialogExtraMessage = '';
    appstore.toggleDialog('upgrade', false);
}

</script>