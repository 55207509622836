<template>
    <NuxtLayout name="blank">
        <main class="bg-gradient-to-r min-h-screen flex items-center justify-center pt-8">
            <div class="max-w-md w-full bg-white rounded-lg shadow-md overflow-hidden p-8">
                <div class="text-center">
                    <img src="/assets/illustrations/error.svg" width="235" alt="Friendly error illustration"
                        class="w-3/5 mx-auto hover:scale-110 transition-all duration-300">
                    <h1 class="text-4xl font-fakt-medium text-gray-800 mt-6">Oops...</h1>
                    <p v-if="error" class="mt-2 text-lg text-gray-600">Error {{ error.statusCode }} - {{ error.message }}
                    </p>
                    <p v-else class="mt-2 text-lg text-gray-600">Something went wrong.</p>
                    <p class="mt-4 text-gray-500">Don't worry, we're on it. <br>Meanwhile, let's get you back on track.</p>
                    <div class="mt-8">
                        <a href="/"
                            class="block w-full py-3 px-6 rounded-md text-center bg-wobby-purple-500 text-white font-fakt-medium mb-3 hover:bg-wobby-purple-600 transition duration-300">
                            Homepage
                        </a>
                        <a href="https://wobby.ai/contact/" target="_blank"
                            class="block w-full py-3 px-6 rounded-md text-center border border-gray-300 text-gray-700 font-fakt-medium hover:border-gray-400 transition duration-300">
                            Contact support
                        </a>
                    </div>
                </div>
            </div>
        </main>
    </NuxtLayout>
</template>

<script setup lang="ts">

const props = defineProps({
    error: Object
})

console.log('Error', props.error)

onMounted(() => {
    document.title = 'Error'
    console.error(props.error)
})

</script>

<style>
html {
    min-height: 100%;
    width: 100%;
}

body {
    min-height: 100%;
    width: 100%;
}
</style>