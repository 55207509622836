import { useIdentityStore } from "@/stores/identity";

export default defineNuxtRouteMiddleware(async (to, from) => {
    try {
        const identityStore = useIdentityStore()
        const { isUserInActiveTrial, user } = storeToRefs(identityStore)
        const { checkSubscriptionStatus } = useIdentityService();

        // check if user is already in 'trial-expired' page
        if (to.path === '/trial-expired') return;
        if (to.path === '/subscription-ended') return;
        // check if the user is on onboarding
        if (to.path === '/auth/onboarding') return;
        // allow access to account-related pages
        if (to.path.includes('account')) return;

        if (!user.value?.trialEndsAt) return;
        if (isUserInActiveTrial.value) return;


        const trialIsInPast = new Date(user.value?.trialEndsAt!) < new Date();
        const subscription = await checkSubscriptionStatus();
        if (subscription.hasSubscription) return;
        if (subscription.hadSubscription) return navigateTo('/subscription-ended');
        if (trialIsInPast) return navigateTo('/trial-expired');
    } catch (error) {
        console.error(error);
        return;
    }
    return;
})