<template>
  <div>
    <NuxtLoadingIndicator color="#7547ff" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <ClientOnly>
      <!-- Fix Hydration errors -->
      <DialogsOrganizationLimitExceededDialog />
      <UNotifications />
      <SignoutDialog />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import 'echarts'
import { watch, onMounted, ref } from 'vue'
import SignoutDialog from './components/dialogs/SignoutDialog.vue';
import Intercom from '@intercom/messenger-js-sdk';
import { useIntervalFn } from '@vueuse/core';

const identityStore = useIdentityStore();
const { getFeatureFlags } = useConfigService();
const { initBanner } = useAppStore();
await initBanner();
const mixpanel = useMixpanel();
const { user } = storeToRefs(identityStore);

const createdAtToUnixTimestamp = (createdAt: string | undefined) => {
  if (!createdAt) return undefined;
  return Math.floor(new Date(createdAt).getTime() / 1000);
}
const { ping } = useIdentityService();


// 2 minutes
const { pause, resume, isActive } = useIntervalFn(ping, 120000);
onMounted(() => {
  getFeatureFlags(['intercom', 'mixpanel']).then((data) => {
    if (data.intercom) {
      Intercom({
        app_id: 'd1rmhbzl',
        user_id: user.value?.id,
        name: user.value?.name,
        email: user.value?.email,
        company: {
          id: user.value?.organization.id,
          name: user.value?.organization.name,
          plan: user.value?.organization.tier.name,
        },
        created_at: createdAtToUnixTimestamp(user.value?.createdAt),
        user_hash: user.value?.intercomUserIdHmacHash || '',
      });
    }

    if (data.mixpanel) {
      if (mixpanel) {
        if (user.value) {
          mixpanel.identify(user.value.email);
          mixpanel.setUserProperties({
            email: user.value.email,
            name: user.value.name,
            organization: `${user.value.organization.name}`,
            country: user.value.country,
            trialEndsAt: user.value.trialEndsAt ?? '',
            createdAt: user.value.createdAt,
          })
        }
      }
    }
  }).catch((error) => {
    console.log('Intercom/mixpanel not loaded');
  });
});

</script>