export default defineAppConfig({
    ui: {
        ions: {
            position: 'top-0 bottom-[unset]'
        },
        primary: 'wobby-purple',
        gray: 'cool',
        safelistColors: ['wobby-purple'],
        tooltip: {
            background: 'bg-wobby-white',
            color: "text-gray-700",
            ring: 'ring-1 ring-wobby-purple-500 ',
            rounded: 'rounded-md',
            shadow: 'shadow-xl',
        },
        selectMenu: {
            input: 'block truncate text-wobby-purple-500',
            ring: 'ring-1 ring-gray-700 ring-opacity-20',
            option: {
                base: 'relative cursor-default select-none py-2 pl-4 pr-2',
                active: 'bg-wobby-purple-100 text-wobby-purple-900',
                inactive: 'text-gray-900',
            },
            transition: {
                leaveActiveClass: "",
                leaveFromClass: "",
                leaveToClass: ""
            }
        },
        
    }
})