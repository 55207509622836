export default defineNuxtRouteMiddleware(async (to, from) => {
    if (process.client) return;

    if (to.fullPath === "/maintenance") return;

    try {
        const { getFeatureFlags } = useConfigService();

        const keys = ["maintenance-mode"];
        const response = await getFeatureFlags(keys);
        if (response["maintenance-mode"]) {
            return navigateTo("/maintenance");
        }
    } catch (error) {
        console.error(error);
    }
});
