<template>
  <UDashboardModal v-model="appStore.dialogs.signout" title="Sign out" description="Are you sure you want to sign out?"
    icon="i-heroicons-exclamation-circle" :ui="{
      icon: { base: 'text-red-500 dark:text-red-400' } as any,
      footer: { base: 'ml-16' } as any
    }">
    <template #footer>
      <UButton color="red" label="Sign Out" :loading="loading" @click="submit" />
      <UButton color="white" label="Cancel" @click="appStore.toggleDialog('signout', false)" />
    </template>
  </UDashboardModal>
</template>

<script setup lang="ts">
import { useAppStore } from '@/stores/app';
import { ref } from 'vue';
import { useIdentityService } from '@/composables/useIdentityService';

const appStore = useAppStore();
const identityService = useIdentityService();

const loading = ref(false)

const submit = async () => {
  try {
    loading.value = true;
    await identityService.signout();
    location.reload();
  } catch (error) {
    alert(error)
  } finally {
    loading.value = false;
  }
}

</script>