import { default as billinggASQZ2y793Meta } from "/app/pages/account/billing.vue?macro=true";
import { default as integrations81yhpsd0paMeta } from "/app/pages/account/integrations.vue?macro=true";
import { default as organizationhbTVrIBe1rMeta } from "/app/pages/account/organization.vue?macro=true";
import { default as indexi95luT1yuGMeta } from "/app/pages/account/preferences/index.vue?macro=true";
import { default as index8qSs2sWXRfMeta } from "/app/pages/account/preferences/themes/index.vue?macro=true";
import { default as newi9B9Ng2RiFMeta } from "/app/pages/account/preferences/themes/new.vue?macro=true";
import { default as profilelwi6rEuniiMeta } from "/app/pages/account/profile.vue?macro=true";
import { default as indexrkU6qSKstoMeta } from "/app/pages/account/prompts/index.vue?macro=true";
import { default as securitySrTMI42t3JMeta } from "/app/pages/account/security.vue?macro=true";
import { default as accountIWnKuvcAuMMeta } from "/app/pages/account.vue?macro=true";
import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as onboardingrnRpgFpRSCMeta } from "/app/pages/auth/onboarding.vue?macro=true";
import { default as registerTCvAsPw3TEMeta } from "/app/pages/auth/register.vue?macro=true";
import { default as reset_45password5ABZjlJW61Meta } from "/app/pages/auth/reset-password.vue?macro=true";
import { default as indexDwANpXzorwMeta } from "/app/pages/blank/index.vue?macro=true";
import { default as _91id_93K0SzrbYCE1Meta } from "/app/pages/discovery/[id].vue?macro=true";
import { default as indexXampRaqRJoMeta } from "/app/pages/discovery/index.vue?macro=true";
import { default as searchTeGvZHelvAMeta } from "/app/pages/discovery/search.vue?macro=true";
import { default as _91id_93R8TPy5wqkzMeta } from "/app/pages/documents/[id].vue?macro=true";
import { default as indexpEqeHBee6QMeta } from "/app/pages/documents/index.vue?macro=true";
import { default as indexKL1s9FFNEvMeta } from "/app/pages/favorites/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91id_93OyZohIATAzMeta } from "/app/pages/knowledge/[id].vue?macro=true";
import { default as indexNu882nngOuMeta } from "/app/pages/knowledge/index.vue?macro=true";
import { default as oldbvf2OY8UQHMeta } from "/app/pages/knowledge/old.vue?macro=true";
import { default as submit_45datarXLx5kIrhgMeta } from "/app/pages/knowledge/submit-data.vue?macro=true";
import { default as maintenanceM1L3mPagESMeta } from "/app/pages/maintenance.vue?macro=true";
import { default as indexcYLHwCDNydMeta } from "/app/pages/reports/index.vue?macro=true";
import { default as searchfcnTL5fl5XMeta } from "/app/pages/search.vue?macro=true";
import { default as stat_45converterdpPD3dwW9AMeta } from "/app/pages/stat-converter.vue?macro=true";
import { default as subscription_45ended1Fp5zfFaRMMeta } from "/app/pages/subscription-ended.vue?macro=true";
import { default as trial_45expired99ylDodSwzMeta } from "/app/pages/trial-expired.vue?macro=true";
import { default as _91id_93uCeTtx3lLJMeta } from "/app/pages/workspaces/chat/[id].vue?macro=true";
import { default as indexZgVFd0ej34Meta } from "/app/pages/workspaces/index.vue?macro=true";
import { default as _91id_93fVKXqiMiyLMeta } from "/app/pages/workspaces/shared/[id].vue?macro=true";
import { default as indexq8mBsbUkbpMeta } from "/app/pages/workspaces/shared/index.vue?macro=true";
import { default as workspacesgXK86voTRHMeta } from "/app/pages/workspaces.vue?macro=true";
export default [
  {
    name: accountIWnKuvcAuMMeta?.name ?? "account",
    path: accountIWnKuvcAuMMeta?.path ?? "/account",
    meta: accountIWnKuvcAuMMeta || {},
    alias: accountIWnKuvcAuMMeta?.alias || [],
    redirect: accountIWnKuvcAuMMeta?.redirect,
    component: () => import("/app/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: billinggASQZ2y793Meta?.name ?? "account-billing",
    path: billinggASQZ2y793Meta?.path ?? "billing",
    meta: billinggASQZ2y793Meta || {},
    alias: billinggASQZ2y793Meta?.alias || [],
    redirect: billinggASQZ2y793Meta?.redirect,
    component: () => import("/app/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: integrations81yhpsd0paMeta?.name ?? "account-integrations",
    path: integrations81yhpsd0paMeta?.path ?? "integrations",
    meta: integrations81yhpsd0paMeta || {},
    alias: integrations81yhpsd0paMeta?.alias || [],
    redirect: integrations81yhpsd0paMeta?.redirect,
    component: () => import("/app/pages/account/integrations.vue").then(m => m.default || m)
  },
  {
    name: organizationhbTVrIBe1rMeta?.name ?? "account-organization",
    path: organizationhbTVrIBe1rMeta?.path ?? "organization",
    meta: organizationhbTVrIBe1rMeta || {},
    alias: organizationhbTVrIBe1rMeta?.alias || [],
    redirect: organizationhbTVrIBe1rMeta?.redirect,
    component: () => import("/app/pages/account/organization.vue").then(m => m.default || m)
  },
  {
    name: indexi95luT1yuGMeta?.name ?? "account-preferences",
    path: indexi95luT1yuGMeta?.path ?? "preferences",
    meta: indexi95luT1yuGMeta || {},
    alias: indexi95luT1yuGMeta?.alias || [],
    redirect: indexi95luT1yuGMeta?.redirect,
    component: () => import("/app/pages/account/preferences/index.vue").then(m => m.default || m)
  },
  {
    name: index8qSs2sWXRfMeta?.name ?? "account-preferences-themes",
    path: index8qSs2sWXRfMeta?.path ?? "preferences/themes",
    meta: index8qSs2sWXRfMeta || {},
    alias: index8qSs2sWXRfMeta?.alias || [],
    redirect: index8qSs2sWXRfMeta?.redirect,
    component: () => import("/app/pages/account/preferences/themes/index.vue").then(m => m.default || m)
  },
  {
    name: newi9B9Ng2RiFMeta?.name ?? "account-preferences-themes-new",
    path: newi9B9Ng2RiFMeta?.path ?? "preferences/themes/new",
    meta: newi9B9Ng2RiFMeta || {},
    alias: newi9B9Ng2RiFMeta?.alias || [],
    redirect: newi9B9Ng2RiFMeta?.redirect,
    component: () => import("/app/pages/account/preferences/themes/new.vue").then(m => m.default || m)
  },
  {
    name: profilelwi6rEuniiMeta?.name ?? "account-profile",
    path: profilelwi6rEuniiMeta?.path ?? "profile",
    meta: profilelwi6rEuniiMeta || {},
    alias: profilelwi6rEuniiMeta?.alias || [],
    redirect: profilelwi6rEuniiMeta?.redirect,
    component: () => import("/app/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: indexrkU6qSKstoMeta?.name ?? "account-prompts",
    path: indexrkU6qSKstoMeta?.path ?? "prompts",
    meta: indexrkU6qSKstoMeta || {},
    alias: indexrkU6qSKstoMeta?.alias || [],
    redirect: indexrkU6qSKstoMeta?.redirect,
    component: () => import("/app/pages/account/prompts/index.vue").then(m => m.default || m)
  },
  {
    name: securitySrTMI42t3JMeta?.name ?? "account-security",
    path: securitySrTMI42t3JMeta?.path ?? "security",
    meta: securitySrTMI42t3JMeta || {},
    alias: securitySrTMI42t3JMeta?.alias || [],
    redirect: securitySrTMI42t3JMeta?.redirect,
    component: () => import("/app/pages/account/security.vue").then(m => m.default || m)
  }
]
  },
  {
    name: loginikXFF2hTKwMeta?.name ?? "auth-login",
    path: loginikXFF2hTKwMeta?.path ?? "/auth/login",
    meta: loginikXFF2hTKwMeta || {},
    alias: loginikXFF2hTKwMeta?.alias || [],
    redirect: loginikXFF2hTKwMeta?.redirect,
    component: () => import("/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: onboardingrnRpgFpRSCMeta?.name ?? "auth-onboarding",
    path: onboardingrnRpgFpRSCMeta?.path ?? "/auth/onboarding",
    meta: onboardingrnRpgFpRSCMeta || {},
    alias: onboardingrnRpgFpRSCMeta?.alias || [],
    redirect: onboardingrnRpgFpRSCMeta?.redirect,
    component: () => import("/app/pages/auth/onboarding.vue").then(m => m.default || m)
  },
  {
    name: registerTCvAsPw3TEMeta?.name ?? "auth-register",
    path: registerTCvAsPw3TEMeta?.path ?? "/auth/register",
    meta: registerTCvAsPw3TEMeta || {},
    alias: registerTCvAsPw3TEMeta?.alias || [],
    redirect: registerTCvAsPw3TEMeta?.redirect,
    component: () => import("/app/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: reset_45password5ABZjlJW61Meta?.name ?? "auth-reset-password",
    path: reset_45password5ABZjlJW61Meta?.path ?? "/auth/reset-password",
    meta: reset_45password5ABZjlJW61Meta || {},
    alias: reset_45password5ABZjlJW61Meta?.alias || [],
    redirect: reset_45password5ABZjlJW61Meta?.redirect,
    component: () => import("/app/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: indexDwANpXzorwMeta?.name ?? "blank",
    path: indexDwANpXzorwMeta?.path ?? "/blank",
    meta: indexDwANpXzorwMeta || {},
    alias: indexDwANpXzorwMeta?.alias || [],
    redirect: indexDwANpXzorwMeta?.redirect,
    component: () => import("/app/pages/blank/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93K0SzrbYCE1Meta?.name ?? "discovery-id",
    path: _91id_93K0SzrbYCE1Meta?.path ?? "/discovery/:id()",
    meta: _91id_93K0SzrbYCE1Meta || {},
    alias: _91id_93K0SzrbYCE1Meta?.alias || [],
    redirect: _91id_93K0SzrbYCE1Meta?.redirect,
    component: () => import("/app/pages/discovery/[id].vue").then(m => m.default || m)
  },
  {
    name: indexXampRaqRJoMeta?.name ?? "discovery",
    path: indexXampRaqRJoMeta?.path ?? "/discovery",
    meta: indexXampRaqRJoMeta || {},
    alias: indexXampRaqRJoMeta?.alias || [],
    redirect: indexXampRaqRJoMeta?.redirect,
    component: () => import("/app/pages/discovery/index.vue").then(m => m.default || m)
  },
  {
    name: searchTeGvZHelvAMeta?.name ?? "discovery-search",
    path: searchTeGvZHelvAMeta?.path ?? "/discovery/search",
    meta: searchTeGvZHelvAMeta || {},
    alias: searchTeGvZHelvAMeta?.alias || [],
    redirect: searchTeGvZHelvAMeta?.redirect,
    component: () => import("/app/pages/discovery/search.vue").then(m => m.default || m)
  },
  {
    name: _91id_93R8TPy5wqkzMeta?.name ?? "documents-id",
    path: _91id_93R8TPy5wqkzMeta?.path ?? "/documents/:id()",
    meta: _91id_93R8TPy5wqkzMeta || {},
    alias: _91id_93R8TPy5wqkzMeta?.alias || [],
    redirect: _91id_93R8TPy5wqkzMeta?.redirect,
    component: () => import("/app/pages/documents/[id].vue").then(m => m.default || m)
  },
  {
    name: indexpEqeHBee6QMeta?.name ?? "documents",
    path: indexpEqeHBee6QMeta?.path ?? "/documents",
    meta: indexpEqeHBee6QMeta || {},
    alias: indexpEqeHBee6QMeta?.alias || [],
    redirect: indexpEqeHBee6QMeta?.redirect,
    component: () => import("/app/pages/documents/index.vue").then(m => m.default || m)
  },
  {
    name: indexKL1s9FFNEvMeta?.name ?? "favorites",
    path: indexKL1s9FFNEvMeta?.path ?? "/favorites",
    meta: indexKL1s9FFNEvMeta || {},
    alias: indexKL1s9FFNEvMeta?.alias || [],
    redirect: indexKL1s9FFNEvMeta?.redirect,
    component: () => import("/app/pages/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93OyZohIATAzMeta?.name ?? "knowledge-id",
    path: _91id_93OyZohIATAzMeta?.path ?? "/knowledge/:id()",
    meta: _91id_93OyZohIATAzMeta || {},
    alias: _91id_93OyZohIATAzMeta?.alias || [],
    redirect: _91id_93OyZohIATAzMeta?.redirect,
    component: () => import("/app/pages/knowledge/[id].vue").then(m => m.default || m)
  },
  {
    name: indexNu882nngOuMeta?.name ?? "knowledge",
    path: indexNu882nngOuMeta?.path ?? "/knowledge",
    meta: indexNu882nngOuMeta || {},
    alias: indexNu882nngOuMeta?.alias || [],
    redirect: indexNu882nngOuMeta?.redirect,
    component: () => import("/app/pages/knowledge/index.vue").then(m => m.default || m)
  },
  {
    name: oldbvf2OY8UQHMeta?.name ?? "knowledge-old",
    path: oldbvf2OY8UQHMeta?.path ?? "/knowledge/old",
    meta: oldbvf2OY8UQHMeta || {},
    alias: oldbvf2OY8UQHMeta?.alias || [],
    redirect: oldbvf2OY8UQHMeta?.redirect,
    component: () => import("/app/pages/knowledge/old.vue").then(m => m.default || m)
  },
  {
    name: submit_45datarXLx5kIrhgMeta?.name ?? "knowledge-submit-data",
    path: submit_45datarXLx5kIrhgMeta?.path ?? "/knowledge/submit-data",
    meta: submit_45datarXLx5kIrhgMeta || {},
    alias: submit_45datarXLx5kIrhgMeta?.alias || [],
    redirect: submit_45datarXLx5kIrhgMeta?.redirect,
    component: () => import("/app/pages/knowledge/submit-data.vue").then(m => m.default || m)
  },
  {
    name: maintenanceM1L3mPagESMeta?.name ?? "maintenance",
    path: maintenanceM1L3mPagESMeta?.path ?? "/maintenance",
    meta: maintenanceM1L3mPagESMeta || {},
    alias: maintenanceM1L3mPagESMeta?.alias || [],
    redirect: maintenanceM1L3mPagESMeta?.redirect,
    component: () => import("/app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: indexcYLHwCDNydMeta?.name ?? "reports",
    path: indexcYLHwCDNydMeta?.path ?? "/reports",
    meta: indexcYLHwCDNydMeta || {},
    alias: indexcYLHwCDNydMeta?.alias || [],
    redirect: indexcYLHwCDNydMeta?.redirect,
    component: () => import("/app/pages/reports/index.vue").then(m => m.default || m)
  },
  {
    name: searchfcnTL5fl5XMeta?.name ?? "search",
    path: searchfcnTL5fl5XMeta?.path ?? "/search",
    meta: searchfcnTL5fl5XMeta || {},
    alias: searchfcnTL5fl5XMeta?.alias || [],
    redirect: searchfcnTL5fl5XMeta?.redirect,
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: stat_45converterdpPD3dwW9AMeta?.name ?? "stat-converter",
    path: stat_45converterdpPD3dwW9AMeta?.path ?? "/stat-converter",
    meta: stat_45converterdpPD3dwW9AMeta || {},
    alias: stat_45converterdpPD3dwW9AMeta?.alias || [],
    redirect: stat_45converterdpPD3dwW9AMeta?.redirect,
    component: () => import("/app/pages/stat-converter.vue").then(m => m.default || m)
  },
  {
    name: subscription_45ended1Fp5zfFaRMMeta?.name ?? "subscription-ended",
    path: subscription_45ended1Fp5zfFaRMMeta?.path ?? "/subscription-ended",
    meta: subscription_45ended1Fp5zfFaRMMeta || {},
    alias: subscription_45ended1Fp5zfFaRMMeta?.alias || [],
    redirect: subscription_45ended1Fp5zfFaRMMeta?.redirect,
    component: () => import("/app/pages/subscription-ended.vue").then(m => m.default || m)
  },
  {
    name: trial_45expired99ylDodSwzMeta?.name ?? "trial-expired",
    path: trial_45expired99ylDodSwzMeta?.path ?? "/trial-expired",
    meta: trial_45expired99ylDodSwzMeta || {},
    alias: trial_45expired99ylDodSwzMeta?.alias || [],
    redirect: trial_45expired99ylDodSwzMeta?.redirect,
    component: () => import("/app/pages/trial-expired.vue").then(m => m.default || m)
  },
  {
    name: workspacesgXK86voTRHMeta?.name ?? undefined,
    path: workspacesgXK86voTRHMeta?.path ?? "/workspaces",
    meta: workspacesgXK86voTRHMeta || {},
    alias: workspacesgXK86voTRHMeta?.alias || [],
    redirect: workspacesgXK86voTRHMeta?.redirect,
    component: () => import("/app/pages/workspaces.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93uCeTtx3lLJMeta?.name ?? "workspaces-chat-id",
    path: _91id_93uCeTtx3lLJMeta?.path ?? "chat/:id()",
    meta: _91id_93uCeTtx3lLJMeta || {},
    alias: _91id_93uCeTtx3lLJMeta?.alias || [],
    redirect: _91id_93uCeTtx3lLJMeta?.redirect,
    component: () => import("/app/pages/workspaces/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: indexZgVFd0ej34Meta?.name ?? "workspaces",
    path: indexZgVFd0ej34Meta?.path ?? "",
    meta: indexZgVFd0ej34Meta || {},
    alias: indexZgVFd0ej34Meta?.alias || [],
    redirect: indexZgVFd0ej34Meta?.redirect,
    component: () => import("/app/pages/workspaces/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93fVKXqiMiyLMeta?.name ?? "workspaces-shared-id",
    path: _91id_93fVKXqiMiyLMeta?.path ?? "shared/:id()",
    meta: _91id_93fVKXqiMiyLMeta || {},
    alias: _91id_93fVKXqiMiyLMeta?.alias || [],
    redirect: _91id_93fVKXqiMiyLMeta?.redirect,
    component: () => import("/app/pages/workspaces/shared/[id].vue").then(m => m.default || m)
  },
  {
    name: indexq8mBsbUkbpMeta?.name ?? "workspaces-shared",
    path: indexq8mBsbUkbpMeta?.path ?? "shared",
    meta: indexq8mBsbUkbpMeta || {},
    alias: indexq8mBsbUkbpMeta?.alias || [],
    redirect: indexq8mBsbUkbpMeta?.redirect,
    component: () => import("/app/pages/workspaces/shared/index.vue").then(m => m.default || m)
  }
]
  }
]