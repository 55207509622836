import { Schematic as SchematicCsr } from "@schematichq/schematic-js";
import type { User } from "~/types";

let schematicInstance: SchematicCsr | null = null

export function useSchematicHq() {
    if (!schematicInstance && process.client) {
        schematicInstance = new SchematicCsr(location.host === 'app.wobby.ai' ? "api_8o9ecb1Kr3b" : "api_WtRw7bw17sp");
    }

    async function identify(user: User) {
        if (process.client && schematicInstance) {
            const identityService = useIdentityService();
            const { authenticated } = useIdentityStore();
            if (!authenticated) return;
            try {
                await schematicInstance!.identify({
                    name: user.name,
                    keys: {
                        id: user.id,
                    },
                    company: {
                        keys: {
                            id: user.organization.id,
                            stripe_customer_id: user.organization.stripeCustomerId,
                            company_id: user.organization.id,
                        },
                        traits: {
                            seats: (await identityService.getOrganizationUsers(user.organization.id)).length,
                        },
                        name: user.organization.name,
                    }
                })
            } catch (e) {
                console.log(e)
            }
        }

    }

    async function getFeatureFlag(flag: FeatureFlag): Promise<boolean> {
        try {
            if (process.client) {
                if (!schematicInstance) return false
                const featureFlag = await schematicInstance.checkFlag({
                    key: flag,
                    fallback: true,
                })
                return featureFlag
            }
            return true;
        } catch (e) {
            console.log(e)
            return true
        }

    }


    return {
        identify,
        getFeatureFlag,
    }
}
